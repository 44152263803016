// AddEvent.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddEvent.scss";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3003"; // Ensure this matches your backend URL

function AddEvent() {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [image, setImage] = useState(null);
  const [events, setEvents] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${API_URL}/events`);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      alert("Failed to fetch events. Please try again.");
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("date", date);
    formData.append("time", time);
    if (image) {
      formData.append("image", image);
    }

    try {
      if (editId) {
        await axios.put(`${API_URL}/events/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Event updated successfully!");
      } else {
        await axios.post(`${API_URL}/events`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Event added successfully!");
      }
      fetchEvents();
      resetForm();
    } catch (error) {
      console.error("Error saving event:", error);
      alert("Failed to save event. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/events/${id}`);
      fetchEvents();
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("There was an error deleting the event!");
    }
  };

  const handleEdit = (event) => {
    setHeading(event.heading);
    setDescription(event.description);
    setDate(event.date);
    setTime(event.time);
    setEditId(event.id);
  };

  const resetForm = () => {
    setHeading("");
    setDescription("");
    setDate("");
    setTime("");
    setImage(null);
    setEditId(null);
  };

  return (
    <div className="member-main-div">
      <div className="add-team-member">
        <h1 className="title-member">
          {editId ? "Update Event" : "Add Event"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Heading:</label>
            <input
              className="input-members"
              type="text"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              placeholder="Event Heading"
              required
            />
          </div>
          <div>
            <label>Description:</label>
            <textarea
              className="input-members"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Event Description"
              required
            />
          </div>
          <div>
            <label>Date:</label>
            <input
              className="input-members"
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Time:</label>
            <input
              className="input-members"
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Image:</label>
            <input type="file" onChange={handleFileChange} />
          </div>
          <button className="button-add-team" type="submit">
            {editId ? "Update Event" : "Add Event"}
          </button>
        </form>
      </div>

      <div className="team-members-container">
        <h2 className="title-member-result">Events</h2>
        {events && events.length > 0 ? (
          events.map((event) => (
            <div key={event.id} className="team-member-card">
              <div className="team-member-info">
                <img
                  className="team-member-image"
                  src={`${API_URL}/events/${event.id}/image`}
                  alt={event.heading}
                />
                <div>
                  <span className="team-member-name">{event.heading}</span>
                  <span className="team-member-designation">
                    {event.description}
                  </span>
                  <span className="team-member-category">{`${event.date} at ${event.time}`}</span>
                </div>
              </div>
              <div className="team-member-actions">
                <button onClick={() => handleEdit(event)}>Edit</button>
                <button onClick={() => handleDelete(event.id)}>Delete</button>
              </div>
            </div>
          ))
        ) : (
          <p>No events found.</p>
        )}
      </div>
    </div>
  );
}

export default AddEvent;
