import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddAlbumCategory.scss";

const API_URL = process.env.REACT_APP_API_URL;

function AddAlbumCategory() {
  const [categories, setCategories] = useState([]);
  const [name, setName] = useState("");
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/album-categories`);
      setCategories(response.data);
      console.log(categories);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await axios.put(`${API_URL}/album-categories/${editId}`, { name });
        alert("Category updated successfully!");
      } else {
        await axios.post(`${API_URL}/album-categories`, { name });
        alert("Category added successfully!");
      }
      fetchCategories();
      setName("");
      setEditId(null);
    } catch (error) {
      console.error("Error saving category", error);
    }
  };

  const handleEdit = (category) => {
    setName(category.name);
    setEditId(category.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/album-categories/${id}`);
      fetchCategories();
    } catch (error) {
      console.error("Error deleting category", error);
    }
  };

  return (
    <div className="category-main-div">
      <div className="add-album-category">
        <h2 className="title-category">{editId ? "Update Album Category" : "Add Album Category"}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Category Name"
              required
              className="input-category"
            />
          </div>
          <button type="submit" className="button-category">
            {editId ? "Update Category" : "Add Category"}
          </button>
        </form>
      </div>
      <div className="category-list">
        <h2>Categories</h2>
        <ul>
          {categories.map((category) => (
            <li key={category.id} className="category-item">
              <span>{category.name}</span>
              <div className="category-actions">
                <button onClick={() => handleEdit(category)} className="edit-button">Edit</button>
                <button onClick={() => handleDelete(category.id)} className="delete-button">Delete</button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AddAlbumCategory;
