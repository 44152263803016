import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddCategory.scss";

const API_URL = process.env.REACT_APP_API_URL;

function AddCategory() {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/categories`);
      setCategories(response.data);
    } catch (error) {
      alert("Failed to fetch categories. Please try again.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editId) {
        await axios.put(`${API_URL}/categories/${editId}`, { name });
        alert("Category updated successfully!");
      } else {
        await axios.post(`${API_URL}/categories`, { name });
        alert("Category added successfully!");
      }
      fetchCategories();
      resetForm();
    } catch (error) {
      alert("Failed to save category. Please try again.");
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/categories/${id}`);
      fetchCategories();
    } catch (error) {
      alert("There was an error deleting the category!");
    }
  };

  const handleEdit = (category) => {
    setName(category.name);
    setEditId(category.id);
  };

  const resetForm = () => {
    setName("");
    setEditId(null);
  };

  return (
    <div className="category-main-div">
      <div className="add-category">
        <h1 className="category-title">
          {editId ? "Update Category" : "Add Category"}
        </h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Category Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Category Name"
              required
            />
          </div>
          <button type="submit">
            {editId ? "Update Category" : "Add Category"}
          </button>
        </form>
      </div>

      <div className="category-list">
        <h1>Categories</h1>
        <ul>
          {categories.map((category) => (
            <li key={category.id}>
              <span>{category.name}</span>
              <button onClick={() => handleEdit(category)}>Edit</button>
              <button onClick={() => handleDelete(category.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AddCategory;
