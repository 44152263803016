import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddAlbum.scss";

const API_URL = process.env.REACT_APP_API_URL;

function AddAlbum() {
  const [categories, setCategories] = useState([]);
  const [albums, setAlbums] = useState([]);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchCategories();
    fetchAlbums();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_URL}/album-categories`);
      setCategories(response.data);
    } catch (error) {
      console.error("Error fetching categories", error);
    }
  };

  const fetchAlbums = async () => {
    try {
      const response = await axios.get(`${API_URL}/albums`);
      setAlbums(response.data);
    } catch (error) {
      console.error("Error fetching albums", error);
    }
  };

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("category_id", category);
    formData.append("title", title);
    formData.append("image", image);

    try {
      if (editId) {
        await axios.put(`${API_URL}/albums/${editId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Album updated successfully!");
      } else {
        await axios.post(`${API_URL}/albums`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        alert("Album added successfully!");
      }
      fetchAlbums();
      resetForm();
    } catch (error) {
      console.error("Error saving album", error);
    }
  };

  const handleEdit = (album) => {
    setTitle(album.title);
    setCategory(album.category_id);
    setEditId(album.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/albums/${id}`);
      fetchAlbums();
    } catch (error) {
      console.error("Error deleting album", error);
    }
  };

  const resetForm = () => {
    setTitle("");
    setCategory("");
    setImage(null);
    setEditId(null);
  };

  return (
    <div className="album-main-div">
      <div className="add-album">
        <h2>{editId ? "Update Image" : "Add Image"}</h2>
        <form onSubmit={handleSubmit}>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="" disabled>
              Select Category
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Album Title"
            required
          />
          <input type="file" onChange={handleFileChange} required />
          <button type="submit">{editId ? "Update Image" : "Add Image"}</button>
        </form>
      </div>

      <div className="albums-list">
        <h2>Image</h2>
        {albums.map((album) => (
          <div key={album.id} className="album-card">
            <img
              src={`${API_URL}/albums/${album.id}/image`}
              alt={album.title}
              className="album-image"
            />
            <div className="album-card-text">
              <h4>{album.title}</h4>
              <button onClick={() => handleEdit(album)} className="edit-button">Edit</button>
              <button onClick={() => handleDelete(album.id)} className="delete-button">Delete</button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AddAlbum;
