import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import AddTeamMembers from "../../Screens/AddTeamMembers/AddTeamMembers";
import AddCategory from "../../Screens/AddCategory/AddCategory";
import AddAlbumCategory from "../../Screens/AddAlbumCategory/AddAlbumCategory";
import AddAlbum from "../../Screens/AddAlbum/AddAlbum";
import { FaBell, FaUserCircle, FaSearch, FaBars } from "react-icons/fa";
import "./AdminPanel.scss";
import logo from "../../aron-logo.png";
import AddJob from "../../Screens/AddJob/AddJob";
import AddEvent from "../../Screens/AddEvent/AddEvent";

function AdminPanel() {
  const handleLogout = () => {
    // Logic for logout
    localStorage.removeItem("auth");
    // Navigate to login page
  };

  const toggleSidebar = () => {
    // Logic to toggle the sidebar
    document.querySelector(".sidebar").classList.toggle("collapsed");
  };

  return (
    <div className="main-panel">
      <div className="admin-panel">
        <Sidebar />
        <div className="content">
          <div className="sticky-navbar">
            <div className="left-section">
              <button className="toggle-sidebar" onClick={toggleSidebar}>
                <FaBars />
              </button>
              <div className="logo-div">
                <img src={logo} className="logo-img" />
              </div>
            </div>
            <div className="right-section">
              <span className="welcome-text">Dashboard</span>
              <FaBell className="icon" />
              <div className="user-profile">
                <FaUserCircle className="icon" />
                <div className="dropdown">
                  <button onClick={handleLogout}>Logout</button>
                </div>
              </div>
            </div>
          </div>

          <Routes>
            <Route path="add-team-members" element={<AddTeamMembers />} />
            <Route path="add-category" element={<AddCategory />} />
            <Route path="add-album-category" element={<AddAlbumCategory />} />
            <Route path="add-album" element={<AddAlbum />} />
            <Route path="add-job" element={<AddJob />} />
            <Route path="add-event" element={<AddEvent />} />
          </Routes>
        </div>
      </div>
    </div>
  );
}

export default AdminPanel;
