// AddJob.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AddJob.scss";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:3003"; // Ensure this matches your backend URL

const AddJob = () => {
  const [jobData, setJobData] = useState({
    title: "",
    location: "",
    workSchedule: "",
    duties: "",
    qualifications: "",
    preferredSkills: "",
    compensationAndBenefits: "",
    description: "",
    requirements: "",
    expectedSalary: "",
  });

  const [jobs, setJobs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [jobIdToEdit, setJobIdToEdit] = useState(null);

  useEffect(() => {
    fetchJobs();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${API_URL}/jobs`); // Matches backend route
      setJobs(response.data);
    } catch (error) {
      alert("Failed to fetch jobs. Please try again.");
      console.error(error); // Added for debugging
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setJobData({
      ...jobData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editMode) {
        await axios.put(`${API_URL}/jobs/${jobIdToEdit}`, jobData); // Matches backend route
        alert("Job updated successfully!");
      } else {
        await axios.post(`${API_URL}/jobs`, jobData); // Matches backend route
        alert("Job added successfully!");
      }
      fetchJobs();
      resetForm();
    } catch (error) {
      alert("Failed to submit job. Please try again.");
      console.error(error); // Added for debugging
    }
  };

  const handleEdit = (job) => {
    setJobData(job);
    setEditMode(true);
    setJobIdToEdit(job.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_URL}/jobs/${id}`); // Matches backend route
      fetchJobs();
      alert("Job deleted successfully!");
    } catch (error) {
      alert("There was an error deleting the job. Please try again.");
      console.error(error); // Added for debugging
    }
  };

  const resetForm = () => {
    setJobData({
      title: "",
      location: "",
      workSchedule: "",
      duties: "",
      qualifications: "",
      preferredSkills: "",
      compensationAndBenefits: "",
      description: "",
      requirements: "",
      expectedSalary: "",
    });
    setEditMode(false);
    setJobIdToEdit(null);
  };

  return (
    <div className="add-job-container">
      <form onSubmit={handleSubmit}>
        <h2>{editMode ? "Edit Job" : "Add New Job"}</h2>
        <input
          type="text"
          name="title"
          placeholder="Job Title"
          value={jobData.title}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="location"
          placeholder="Job Location"
          value={jobData.location}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="workSchedule"
          placeholder="Work Schedule"
          value={jobData.workSchedule}
          onChange={handleChange}
          required
        />
        <textarea
          name="duties"
          placeholder="Job Duties"
          value={jobData.duties}
          onChange={handleChange}
          required
        />
        <textarea
          name="qualifications"
          placeholder="Qualifications"
          value={jobData.qualifications}
          onChange={handleChange}
          required
        />
        <textarea
          name="preferredSkills"
          placeholder="Preferred Skills"
          value={jobData.preferredSkills}
          onChange={handleChange}
        />
        <textarea
          name="compensationAndBenefits"
          placeholder="Compensation and Benefits"
          value={jobData.compensationAndBenefits}
          onChange={handleChange}
          required
        />
        <textarea
          name="description"
          placeholder="Job Description"
          value={jobData.description}
          onChange={handleChange}
          required
        />
        <textarea
          name="requirements"
          placeholder="Job Requirements"
          value={jobData.requirements}
          onChange={handleChange}
          required
        />
        <input
          type="text"
          name="expectedSalary"
          placeholder="Expected Salary"
          value={jobData.expectedSalary}
          onChange={handleChange}
          required
        />
        <button type="submit">{editMode ? "Update Job" : "Add Job"}</button>
      </form>

      <div className="job-list">
        <h2>Current Jobs</h2>
        <ul>
          {jobs.map((job) => (
            <li key={job.id}>
              <h3>{job.title}</h3>
              <p>
                <strong>Location:</strong> {job.location}
              </p>
              <p>
                <strong>Work Schedule:</strong> {job.workSchedule}
              </p>
              <p>
                <strong>Duties:</strong> {job.duties}
              </p>
              <p>
                <strong>Qualifications:</strong> {job.qualifications}
              </p>
              <p>
                <strong>Preferred Skills:</strong> {job.preferredSkills}
              </p>
              <p>
                <strong>Compensation and Benefits:</strong>{" "}
                {job.compensationAndBenefits}
              </p>
              <p>
                <strong>Description:</strong> {job.description}
              </p>
              <p>
                <strong>Requirements:</strong> {job.requirements}
              </p>
              <p>
                <strong>Expected Salary:</strong> ${job.expectedSalary}
              </p>
              <button onClick={() => handleEdit(job)}>Edit</button>
              <button onClick={() => handleDelete(job.id)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddJob;
